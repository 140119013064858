<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>农资管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div v-auth="'assetWarehouse-add'">
        <el-button type="primary" size="small" @click="addAssetWarehouse"
          >新增农资</el-button
        >
        &nbsp;
      </div>
      <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        v-loading="spinning"
        :data="data"
        row-key="id"
      >
        <el-table-column fixed prop="name" label="农资名称"> </el-table-column>
        <el-table-column prop="spec" label="规格"> </el-table-column>
        <el-table-column prop="unit" label="单位"> </el-table-column>
        <el-table-column prop="manufacturers" label="厂家"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="num" label="当前数量"> </el-table-column>
        <el-table-column prop="imgPathAll" label="农资图片">
          <template slot-scope="scope">
            <img
              v-if="scope.row.imgPathAll"
              :src="scope.row.imgPathAll"
              style="padding: 5px; height: 80px; width: auto"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="400"
          label="操作"
          align="center"
          v-if="
            authVerify.verify(
              'assetWarehouse-editNum,assetWarehouse-look,assetWarehouse-edit,assetWarehouse-delete'
            )
          "
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-s-operation"
              size="mini"
              v-auth="'assetWarehouse-editNum'"
              @click="changeNum(scope.row)"
              >修改数量
            </el-button>
            <el-button
              icon="el-icon-document-copy"
              size="mini"
              v-auth="'assetWarehouse-look'"
              @click="getAssetWarehouseLog(scope.row)"
              >农资记录
            </el-button>
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'assetWarehouse-edit'"
              @click="editAssetWarehouse(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'assetWarehouse-delete'"
              @click="deleteAssetWarehouse(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
        :total="totalCount"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
          title="新增农资"
          width="400"
          placement="right"
          :closable="false"
          :visible="addAssetWarehouseVisible"
          @close="addAssetWarehouseVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="assetWarehouseForm"
              ref="assetWarehouseRefForm"
              :rules="assetWarehouseRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="农资名称" prop="name">
                <a-input v-model="assetWarehouseForm.name" allowClear placeholder="请输入农资名称"/>
              </a-form-model-item>
              <a-form-model-item label="规格" prop="spec">
                <a-input v-model="assetWarehouseForm.spec" allowClear placeholder="请输入规格"/>
              </a-form-model-item>
              <a-form-model-item label="单位" prop="unit">
                <a-input v-model="assetWarehouseForm.unit" allowClear placeholder="请输入单位"/>
              </a-form-model-item>
              <a-form-model-item label="厂家" prop="manufacturers">
                <a-input v-model="assetWarehouseForm.manufacturers" allowClear placeholder="请输入厂家"/>
              </a-form-model-item>
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="assetWarehouseForm.remark" allowClear placeholder="请输入备注"/>
              </a-form-model-item>
              <a-form-model-item label="照片" prop="imgPath">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="assetWarehouseForm.imgPathAll"
                      :src="assetWarehouseForm.imgPathAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addAssetWarehouseVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddAssetWarehouse">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
          title="编辑农资"
          width="400"
          placement="right"
          :closable="false"
          :visible="editAssetWarehouseVisible"
          @close="editAssetWarehouseVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="assetWarehouseForm"
              ref="editAssetWarehouseRefForm"
              :rules="assetWarehouseRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="农资名称" prop="name">
                <a-input v-model="assetWarehouseForm.name" allowClear placeholder="请输入农资名称"/>
              </a-form-model-item>
              <a-form-model-item label="规格" prop="spec">
                <a-input v-model="assetWarehouseForm.spec" allowClear placeholder="请输入规格"/>
              </a-form-model-item>
              <a-form-model-item label="单位" prop="unit">
                <a-input v-model="assetWarehouseForm.unit" allowClear placeholder="请输入单位"/>
              </a-form-model-item>
              <a-form-model-item label="厂家" prop="manufacturers">
                <a-input v-model="assetWarehouseForm.manufacturers" allowClear placeholder="请输入厂家"/>
              </a-form-model-item>
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="assetWarehouseForm.remark" allowClear placeholder="请输入备注"/>
              </a-form-model-item>
              <a-form-model-item label="照片" prop="imgPath">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="assetWarehouseForm.imgPathAll"
                      :src="assetWarehouseForm.imgPathAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editAssetWarehouseVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditAssetWarehouse">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--操作记录-->
    <template>
      <div>
        <a-modal
          v-model="assetWarehouseLogVisible"
          title="农资操作记录"
          width="900px"
        >
          <template slot="footer">
            <a-button @click="assetWarehouseLogVisible = false">关闭</a-button>
          </template>
          <div>
            描述:
            <a-input
              v-model="assetWarehouseLogQuery.name"
              placeholder="请输入描述"
              style="width: 200px"
              allowClear
            />
            <el-button
              @click="toGetAssetWarehouseLog"
              style="margin-left: 10px"
              size="small"
            >
              搜索
            </el-button>
          </div>
          <el-table
        header-row-class-name="my-table-head-style"
            style="margin-top: 0; margin-bottom: 0"
            v-loading="logSpinning"
            :data="logData"
            row-key="id"
          >
            <el-table-column fixed prop="type" label="操作类型">
              <template slot-scope="scope">
                {{ scope.row.type | typeFilter }}
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量"> </el-table-column>
            <el-table-column prop="describe" label="描述"> </el-table-column>
            <el-table-column prop="time" label="时间">
              <template slot-scope="scope">
                <span>{{ scope.row.time | dateFormatFull }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="total,sizes, prev, pager, next"
            :page-sizes="[10, 20, 30, 40]"
            :current-page="logCurrentPage"
            :page-size="logPageSize"
            @current-change="logHandlePageChange"
            @size-change="logHandleSizeChange"
            :total="logTotalCount"
          >
          </el-pagination>
        </a-modal>
      </div>
    </template>
    <!--修改数量-->
    <template>
      <div>
        <a-drawer
          title="修改数量"
          width="400"
          placement="right"
          :closable="false"
          :visible="changeNumVisible"
          @close="changeNumVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="assetWarehouseLogForm"
              ref="assetWarehouseLogRefForm"
              :rules="assetWarehouseLogRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="农资名称" prop="assetWarehouseName">
                <a-input
                  v-model="assetWarehouseLogForm.assetWarehouseName"
                  disabled
                />
              </a-form-model-item>
              <a-form-model-item label="操作类型" prop="type">
                <a-select v-model="assetWarehouseLogForm.type" placeholder="请选择操作类型">
                  <a-select-option value="ADD"> 增加 </a-select-option>
                  <a-select-option value="SUBTRACT"> 减少 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="操作数量" prop="num">
                <a-input v-model="assetWarehouseLogForm.num" type="number" placeholder="请输入操作数量"/>
              </a-form-model-item>
              <a-form-model-item label="描述" prop="describe">
                <a-input v-model="assetWarehouseLogForm.describe" placeholder="请输入描述"/>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="changeNumVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toChangeNum"> 确认 </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeNumVisible: false,
      assetWarehouseLogForm: {
        id: "",
        assetWarehouseId: "",
        assetWarehouseName: "",
        type: undefined,
        describe: "",
        num: "",
      },
      assetWarehouseLogRules: {
        assetWarehouseId: [
          { required: true, message: "请选择操作的农资!", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择增加还是减少!", trigger: "blur" },
        ],
        describe: [{ required: true, message: "请输入描述!", trigger: "blur" }],
        num: [{ required: true, message: "请输入操作数量!", trigger: "blur" }],
      },

      assetWarehouseLogQuery: {
        assetWarehouseId: "",
        name: "",
      },
      httpUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${localStorage
        .getItem("access_token")
        .substring(6, localStorage.getItem("access_token").length)}`,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      addAssetWarehouseVisible: false,
      editAssetWarehouseVisible: false,
      assetWarehouseLogVisible: false,
      logSpinning: true,
      logCurrentPage: 1,
      logPageSize: 10,
      logTotalCount: 0,
      logData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      spinning: true,
      data: [],
      assetWarehouseForm: {
        id: "",
        name: "",
        spec: "",
        unit: "",
        manufacturers: "",
        remark: "",
        num: "",
        imgPath: "",
        imgPathAll: "",
      },
      assetWarehouseRules: {
        name: [{ required: true, message: "请输入农资名称!", trigger: "blur" }],
      },
    };
  },
  filters: {
    typeFilter: function (value) {
      if (!value) return "";
      if (value === "ADD") {
        return "手动增加";
      } else if (value === "SUBTRACT") {
        return "手动减少";
      }else if (value === "CROPSUBTRACT") {
          return "农事任务扣减";
      }

    },
  },
  computed: {
    isShowImg() {
      console.log("computed-----" + this.assetWarehouseForm.imgPath);
      if (this.assetWarehouseForm.imgPath === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.assetWarehouseForm.imgPath);
      }
    },
  },
  mounted() {
    this.getAssetWarehouseData();
  },
  created() {},
  methods: {
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },
    imgHandleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.assetWarehouseForm.imgPathAll = file["response"].data.allPath;
        this.assetWarehouseForm.imgPath = file["response"].data.path;
      }
    },
    getAssetWarehouseLog(row) {
      this.assetWarehouseLogQuery = {
        assetWarehouseId: row.id,
        name: "",
      };
      this.toGetAssetWarehouseLog();
      this.assetWarehouseLogVisible = true;
    },
    toGetAssetWarehouseLog() {
      this.http.assetWarehouse
        .getAssetWarehouseLogPageById({
          assetWarehouseId: this.assetWarehouseLogQuery.assetWarehouseId,
          name: this.assetWarehouseLogQuery.name,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.logData = response.data.data.records;
            this.logTotalCount = response.data.data.total;
            this.logCurrentPage = response.data.data.current;
          }
          this.logSpinning = false;
        });
    },
    changeNum(row) {
      this.assetWarehouseLogForm = {
        id: "",
        assetWarehouseId: row.id,
        assetWarehouseName: row.name,
        type: undefined,
        describe: "",
        num: "",
      };
      this.changeNumVisible = true;
        this.$refs.assetWarehouseLogRefForm.resetFields()
    },
    toChangeNum() {
      if (this.assetWarehouseLogForm.type === "ADD") {
        this.addNum();
      } else {
        this.subtractNum();
      }
    },
    addNum() {
      this.$refs.assetWarehouseLogRefForm.validate((valid) => {
        if (valid) {
          this.http.assetWarehouse
            .addAssetWarehouseNum(this.assetWarehouseLogForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.changeNumVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getAssetWarehouseData();
            });
        }
      });
    },
    subtractNum() {
      this.$refs.assetWarehouseLogRefForm.validate((valid) => {
        if (valid) {
          this.http.assetWarehouse
            .subtractAssetWarehouseNum(this.assetWarehouseLogForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.changeNumVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getAssetWarehouseData();
            });
        }
      });
    },
    getAssetWarehouseData() {
      this.http.assetWarehouse
        .getAssetWarehouseListPage({
          size: this.pageSize,
          current: this.currentPage,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.data = response.data.data.records;
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current;
          }
          this.spinning = false;
        });
    },
    cleanAssetWarehouse() {
      this.assetWarehouseForm = {
        id: "",
        name: "",
        spec: "",
        unit: "",
        manufacturers: "",
        remark: "",
        num: "",
        imgPath: "",
        imgPathAll: "",
      };
    },
    addAssetWarehouse() {
      this.addAssetWarehouseVisible = true;
        this.$refs.assetWarehouseRefForm.resetFields()
      this.cleanAssetWarehouse();
    },
    toAddAssetWarehouse() {
      this.$refs.assetWarehouseRefForm.validate((valid) => {
        if (valid) {
          this.http.assetWarehouse
            .createAssetWarehouse(this.assetWarehouseForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addAssetWarehouseVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getAssetWarehouseData();
            });
        }
      });
    },
    toEditAssetWarehouse() {
      this.$refs.editAssetWarehouseRefForm.validate((valid) => {
        if (valid) {
          this.http.assetWarehouse
            .updateAssetWarehouse(this.assetWarehouseForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editAssetWarehouseVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getAssetWarehouseData();
            });
        }
      });
    },
    editAssetWarehouse(data) {
      console.log(data);
      this.cleanAssetWarehouse();
      this.assetWarehouseForm = {
        id: data.id,
        name: data.name,
        spec: data.spec,
        unit: data.unit,
        manufacturers: data.manufacturers,
        remark: data.remark,
        num: data.num,
        imgPath: data.imgPath,
        imgPathAll: data.imgPathAll,
      };
      this.editAssetWarehouseVisible = true;
        this.$refs.editAssetWarehouseRefForm.resetFields()
    },
    deleteAssetWarehouse(data) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.assetWarehouse
            .deleteAssetWarehouse({ id: data.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getAssetWarehouseData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    handlePageChange(val) {
      this.spinning = true;
      this.currentPage = val;
      this.getAssetWarehouseData();
    },
    handleSizeChange(val) {
      this.spinning = true;
      this.pageSize = val;
      this.getAssetWarehouseData();
    },
    logHandlePageChange(val) {
      this.logSpinning = true;
      this.logCurrentPage = val;
      this.toGetAssetWarehouseLog();
    },
    logHandleSizeChange(val) {
      this.logSpinning = true;
      this.logPageSize = val;
      this.toGetAssetWarehouseLog();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
